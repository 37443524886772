<template>
    <div> 
        <TextInput v-model:value="street"   v-model:errors="errors.street" :label="$t('account.location.street.title')" :placeholder="$t('account.location.street.placeholder')" />
        <TextInput v-model:value="city"     v-model:errors="errors.city" :label="$t('account.location.city.title')" :placeholder="$t('account.location.city.placeholder')" class="pt-5" />
        <TextInput v-model:value="province" v-model:errors="errors.province" :label="$t('account.location.province.title')" :placeholder="$t('account.location.province.placeholder')" class="pt-5" />
        <TextInput v-model:value="zip"      v-model:errors="errors.zip" :label="$t('account.location.zip.title')" :placeholder="$t('account.location.zip.placeholder')" class="pt-5" />
        <TextInput v-model:value="country"  v-model:errors="errors.country" :label="$t('account.location.country.title')" :placeholder="$t('account.location.country.placeholder')" class="pt-5" />
    </div>

</template>

<script>
import TextInput from "@/components/forms/TextInput";
import { walkthrough_to_store } from "@/helper/store/walkthrough_to_storage";
import { register_validation } from "@/helper/api/RegistrationHelper";
import { setLoading } from "@/helper/store/loadingSpinnerHelper";
import messages from "@/helper/store/messageHelper";

export default {
    name: "LocationStep",
    components:{
        TextInput,
    }, 
    methods:{
        validate: async function (){
            setLoading(true);

            const data = {
                street:     this.street, 
                city:       this.city, 
                province:   this.province, 
                zip:        this.zip, 
                country:    this.country, 
            }

            const result = await register_validation(data)
                .catch((err) => {
                    messages.error(err.message);
                    return false;
                })
                .finally(() => {
                    setLoading(false);
                });

            // console.log('result', result)

            if (result.success){
                return true;
            } else {
                this.errors = result.errors;
                return false;
            }
        },
    },
    async beforeRouteLeave (to, from, next) {        
        if (to.meta.number > from.meta.number && ! await this.validate()){
            next(false);
        } else {
            next();
        }
    },
    data(){
        return {
            errors: {
                street: [], 
                city: [], 
                province: [], 
                zip: [], 
                country: [], 
            },
        };
    },
    computed: {
        street: walkthrough_to_store('street'),
        city: walkthrough_to_store('city'),
        province: walkthrough_to_store('province'),
        zip: walkthrough_to_store('zip'),
        country: walkthrough_to_store('country'),
    }
}
</script>

<style scoped>

</style>
